<template>
  <tr>
    <td>
      {{ startDate }}
    </td>
    <td class="text-secondary-600">
      <button class="text-left" @click="selectNotification">
        {{ notification.title }}
      </button>
    </td>
    <td>
      {{ notification.category.name }}
    </td>
  </tr>
</template>

<script>
import { formatDate } from '@/helpers/DateFormatters';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDate() {
      return formatDate(this.notification.startDate);
    },
  },
  methods: {
    selectNotification() {
      this.$emit('select', this.notification);
    },
  },
};
</script>
