<template>
  <div class="mx-auto my-10 responsive-container">
    <div class="max-w-[1360px] mx-10 4xl:mx-0 bg-white px-24 py-14 shadow-md">
      <h2 class="mb-16 text-4xl font-sansbold">
        {{ $t('notificationsView.title') }}
      </h2>
      <div>
        <table>
          <tr>
            <th class="w-[200px]">
              <div class="flex items-center">
                {{ $t('notificationsView.tableDate') }}
                <admin-sort-filter
                  ref="sort_start_date"
                  class="flex items-center ml-2"
                  filter-key="start_date"
                  @filter="sortDocuments"
                />
              </div>
            </th>
            <th>
              <div class="flex items-center">
                {{ $t('notificationsView.tableTitle') }}
                <admin-sort-filter
                  ref="sort_title"
                  class="flex items-center ml-2"
                  filter-key="title"
                  @filter="sortDocuments"
                />
              </div>
            </th>
            <th>
              <div class="flex items-center">
                {{ $t('notificationsView.tableCategory') }}
                <admin-sort-filter
                  ref="sort_category__name"
                  class="flex items-center ml-2"
                  filter-key="category__name"
                  @filter="sortDocuments"
                />
              </div>
            </th>
          </tr>
          <notification-list-entry
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            @select="setCurrentNotification"
          />
        </table>
        <notification-modal
          v-if="currentNotification !== null"
          :is-open="currentNotification !== null"
          :notification="currentNotification"
          @close="setCurrentNotification(null)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationListEntry from '@/components/user/NotificationListEntry.vue';
import { getNotifications } from '@/services/notification';
import AdminSortFilter from '@/components/admin/AdminSortFilter';
import NotificationModal from '@/components/user/NotificationModal.vue';
import notificationMixin from '@/components/user/notificationMixin';
import EditorEventBus from '@/helpers/EditorEventBus';

export default {
  components: {
    NotificationListEntry,
    AdminSortFilter,
    NotificationModal,
  },
  mixins: [notificationMixin],
  data() {
    return {
      notifications: [],
    };
  },
  async mounted() {
    await this.loadNotifications();
    EditorEventBus.$on('reloadNotifications', this.loadNotifications);
  },
  beforeDestroy() {
    EditorEventBus.$off('reloadNotifications', this.loadNotifications);
  },
  methods: {
    async loadNotifications() {
      this.notifications = await getNotifications();
    },
    async sortDocuments(sortKey, value) {
      // Reset state from all other orders
      ['start_date', 'title', 'category__name'].forEach((sort) => {
        if (sort !== sortKey) {
          this.$refs['sort_' + sort].resetState();
        }
      });

      this.notifications = await getNotifications(value);
    },
  },
};
</script>
