<template>
  <document-layout class="frontend">
    <notification-list></notification-list>
  </document-layout>
</template>
<script>
import NotificationList from '@/components/user/NotificationList.vue';

export default {
  components: { NotificationList },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>
